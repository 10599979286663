import cockpitContactPersonImageSrc from '../assets/ContactPersonImage/cockpit.jpeg'
import {AppConfig, PLANT_ID} from '../types'

export const cockpit: AppConfig = {
  contact: {
    name: 'Gesa Hartmann',
    mail: 'gesa.hartmann@heidelbergmaterials.com',
    profileImage: cockpitContactPersonImageSrc
  },
  name: 'Cockpit',
  url: {
    qa: `https://cockpit-qa.hproduce.digital/${PLANT_ID}`,
    uat: `https://cockpit-uat.hproduce.digital/${PLANT_ID}`,
    prod: `https://cockpit.hproduce.digital/${PLANT_ID}`
  },

  // 'VIEW_SHIFT_REPORT' and 'VIEW_PLANT_EVENT' is unfortunately also used by RELIABILITY_CONTRIBUTOR
  whiteListedPermissionTypes: ['VIEW_PLANT_TASK']
}
