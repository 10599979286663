import {PermissionType} from '@hconnect/apiclient'

import {MaintainAppOption, MaintainAppName} from './maintain.types'

export type {Permission} from '@hconnect/apiclient'

export type AppName =
  | 'Cockpit'
  | 'Predict'
  | 'Planner'
  | 'easyPOM'
  | 'HProduce'
  | 'Maintain'
  | 'Settings'

export const PLANT_ID = '{plantId}'
export const PLANT_NAME = '{plantName}'

export type TemplateUrl = string // including at least one template parameter

export type Env = 'prod' | 'qa' | 'uat'

export type EnvUrls = Record<Env, TemplateUrl>

export type AppConfig = {
  contact: {
    name: string
    mail: string
    profileImage: string
  }
  name: AppName
  url: EnvUrls
  whiteListedPermissionTypes: PermissionType[]
  plant?: {
    whiteList?: PlantId[]
    blackList?: PlantId[]
  }
}

export type PlantId = string
export type CountryId = string

export type AppPlantSelectOption = PlantSelectOption & {
  appName: AppName
}

export type PlantSelectOption = {
  plantId: PlantId
  plantName: string
  url: string
}

export type AppPlusPlant = string // Like cockpitNl11

export type PlantJsonItem = {name: string; countryId: string}

export type WhiteList = {
  optionsPerApp: Partial<Record<AppName, AppPlantSelectOption[]>>
  maintainApps: Partial<Record<MaintainAppName, MaintainAppOption[]>>
  optionsPerPlant: Record<PlantId, AppPlantSelectOption[]>
  optionsMap: Map<AppPlusPlant, AppPlantSelectOption>
  missedPlantIds: PlantId[]
}
