import predictContactPersonImageSrc from '../assets/ContactPersonImage/predict.jpeg'
import {AppConfig, PLANT_ID} from '../types'

export const predict: AppConfig = {
  contact: {
    name: 'Dr. Christoph Deil',
    mail: 'christoph.deil@heidelbergmaterials.com',
    profileImage: predictContactPersonImageSrc
  },
  name: 'Predict',
  url: {
    qa: `https://predict-qa.hproduce.digital/plants/${PLANT_ID}`,
    uat: `https://predict-uat.hproduce.digital/plants/${PLANT_ID}`,
    prod: `https://predict.hproduce.digital/plants/${PLANT_ID}`
  },
  whiteListedPermissionTypes: ['VIEW_PREDICTIONS'], // ENERGY_MANAGER is missing the VIEW_PREDICTIONS permission
  plant: {
    whiteList: [
      '0014',
      'PL1A',
      'U175',
      'U100',
      'U145',
      'U155',
      'U157',
      'U159',
      'U160',
      'C102',
      'C203',
      'C205'
    ]
  }
}
