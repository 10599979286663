import cockpitContactPersonImageSrc from '../assets/ContactPersonImage/cockpit.jpeg'
import {AppConfig, PLANT_ID} from '../types'

export const easyPOM: AppConfig = {
  contact: {
    name: 'Gesa Hartmann',
    mail: 'gesa.hartmann@heidelbergmaterials.com',
    profileImage: cockpitContactPersonImageSrc
  },
  name: 'easyPOM',
  url: {
    qa: `https://qa.pom.heidelbergcement.com/${PLANT_ID}/dashboard`,
    uat: `https://uat.pom.heidelbergcement.com/${PLANT_ID}/dashboard`,
    prod: `https://pom.heidelbergcement.com/${PLANT_ID}/dashboard`
  },

  whiteListedPermissionTypes: ['VIEW_POM_DASHBOARD']
}
